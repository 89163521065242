import { HTMLMotionProps } from "framer-motion";
import React from "react";
import { Wrapper } from "./styled";

function Button({
  label,
  gradient = false,
  onClick,
  ...props
}: {
  label: React.ReactNode;
  gradient?: boolean;
  onClick?: () => void;
} & HTMLMotionProps<"button">) {
  return (
    <Wrapper onClick={onClick} $gradient={gradient} {...props}>
      {label}
    </Wrapper>
  );
}

export { Button };
