import React from "react";
import grumpy from "../../assets/grumpy-no-bg.png";
import rocket from "./assets/rocket-min.png";
import hatch from "./assets/hatch.png";
import nicegram from "./assets/nicegram-logo-min.png";
import { BlockWrapper, CtaButton, Header, Wrapper } from "./styled";

function Index() {
  React.useEffect(() => {
    window.ym(93758124, "reachGoal", "show_v11");
  }, []);
  return (
    <Wrapper>
      <Header style={{ padding: "20px 16px" }}>
        <img height={250} src={grumpy} alt="" />
        <h1>$GRUM</h1>
        <p>
          Join the future of memecoins <br /> with Grumpy <span>($GRUM)</span>
        </p>
      </Header>
      <BlockWrapper>
        <img height={180} src={rocket} alt="" />
        <h2>
          The Right Time <br /> is{" "}
          <span>
            <img src={hatch} alt="" />
            Now
          </span>
        </h2>
        <p>
          With major exchange listings on the horizon, prices are projected to
          soar. Secure your <span>$GRUM</span> today!
        </p>
      </BlockWrapper>
      <BlockWrapper>
        <img height={140} src={nicegram} alt="" />
        <h2>
          Supported <br /> by Nicegram
        </h2>
        <p>
          Nicegram and its 2.6 million users support the <span>$GRUM</span>{" "}
          movement. This endorsement adds credibility to our rapidly expanding
          community.
        </p>
      </BlockWrapper>
      <BlockWrapper>
        <h2>Take Action Now</h2>
        <ul>
          <li>Join the exclusive Grumpy group for Nicegram users.</li>
          <li>
            Follow instructions in the group to secure your $GRUM at a special
            pre-listing price.
          </li>
          <li>
            Stay updated on the listing date and other crucial information by
            being an active member of the Grumpy chat.
          </li>
          <li>
            Sell your $GRUM at a potential high profit once it lists on the
            exchanges.
          </li>
        </ul>
        <div className="cta-hatch">
          <img src={hatch} alt="" />
          Dont Wait!
        </div>
        <CtaButton
          onClick={() => window.ym(93758124, "reachGoal", "click_v11_main")}
        >
          <a href="ncg://special-offer-click?url=tg://resolve?domain=grumpynicegram">
            Secure your $GRUM now!
          </a>
        </CtaButton>
      </BlockWrapper>
      <div className="cloud" />
    </Wrapper>
  );
}

export default Index;
