const themes = {
  lightBlue: {
    htmlBg: "white",
    bg: "white",
    primaryColor:
      "linear-gradient(263.36deg, #413de8 20.1%, #58b2f4 68.97%), #000000",
    primaryText: "black",
    secondaryText: "rgba(0, 0, 0, 0.55)",
  },
  darkOrange: {
    htmlBg: "black",
    bg: "black",
  },
  darkPurple: {
    htmlBg: "black",
    bg: "black",
  },
};

export default themes;
