import styled from "styled-components";

export const Wrapper = styled.button`
  margin-top: 40px;
  border: none;
  cursor: pointer;
  height: 64px;
  width: 100%;
  font-weight: 800;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(263.36deg, #413de8 20.1%, #58b2f4 68.97%), #000000;
  border-radius: 16px;
  color: white;
  grid-gap: 10px;

  > a {
    text-decoration: none;
    color: inherit;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 10px;
  }
`;
