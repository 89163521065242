import styled from "styled-components";

export const BlockText = styled.p`
  margin: 16px 0 0 0;
  padding: 0;
  color: ${(props) => props.theme.secondaryText};
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: -0.4px;

  span {
    color: ${(props) => props.theme.primaryText};
  }
`;
export const BlockTitle = styled.div`
  margin: 16px 0;
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: 41px;
  letter-spacing: 0.136px;
  background: ${(props) => props.theme.primaryColor};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  span {
    display: inline-block;
    position: relative;
    z-index: 1;
    color: white;
    -webkit-text-fill-color: white;

    > img {
      position: absolute;
      left: -5%;
      top: -5%;
      width: 110%;
      height: 110%;
      z-index: -1;
    }
  }
`;

export const BlockWrapper = styled.div`
  margin: 8px 0;
  padding: 40px 16px;
  text-align: center;
  background: #f5f5f7;
  border-radius: 24px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;

  > span {
    letter-spacing: -0.1px;
    background: linear-gradient(263.36deg, #413de8 20.1%, #58b2f4 68.97%),
      #000000;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  b {
    color: black;
  }

  h1 {
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -0.7px;
    color: #000000;

    span {
      background: linear-gradient(263.36deg, #413de8 20.1%, #58b2f4 68.97%),
        #000000;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }
`;

export const Container = styled.main`
  position: relative;
  overflow: hidden;
  max-width: 466px;
  padding: 0 8px;
`;

export const Alert = styled.div`
  margin: 32px 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  padding: 16px;
  background: rgba(255, 255, 255, 0.15);
  border: 1px dashed rgba(255, 255, 255, 0.15);
  border-radius: 6px;
  color: white;
  text-align: center;

  span {
    color: #ff9500;
  }
`;

export const ImageWrapper = styled.div`
  max-height: 200px;
  border-radius: 6px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const Card = styled.div`
  position: relative;
  margin: 8px 0;
  padding: 8px 16px;
  text-align: center;
  background: #f5f5f7;
  border-radius: 24px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;

  &:has(> img:first-child) {
    padding: 32px 16px 8px 16px;

    h2 {
      padding-top: 16px;
    }
  }

  > ul {
    margin: 32px 0;
    padding: 0;

    li {
      padding-left: 36px;
      position: relative;
      margin: 16px 0;
      align-items: center;
      text-align: left;
      grid-gap: 12px;
      counter-increment: counter;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.4px;
      color: rgba(0, 0, 0, 0.55);
      list-style: none;

      &:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "0" counter(counter);
        display: flex;
        align-items: center;
        justify-content: center;
        color: #0a84ff;
        background: linear-gradient(
          21.43deg,
          rgba(65, 61, 232, 0.1) -14%,
          rgba(88, 178, 244, 0.1) 105.54%
        );
        border-radius: 6px;
        padding: 4px;
        height: 100%;
        flex: 0 0 24px;
        font-weight: 800;
        font-size: 12px;
        line-height: 12px;
        letter-spacing: 0.1px;
      }
    }
  }

  > span {
    letter-spacing: -0.1px;
    background: linear-gradient(263.36deg, #413de8 20.1%, #58b2f4 68.97%),
      #000000;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  b {
    color: black;
  }

  h1 {
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -0.7px;
    color: #000000;

    span {
      background: linear-gradient(263.36deg, #413de8 20.1%, #58b2f4 68.97%),
        #000000;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }

  h2 {
    border: none;
    margin: 0 0 16px 0;
    font-weight: 800;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: -0.2px;
    background: linear-gradient(263.36deg, #413de8 20.1%, #58b2f4 68.97%),
      #000000;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  h4 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.3);
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.4px;
    color: rgba(0, 0, 0, 0.5);
    margin: 16px 0 0 0;

    &:last-of-type {
      padding-bottom: 32px;
    }
  }

  > button {
    border: none;
    cursor: pointer;
    height: 64px;
    width: 100%;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(263.36deg, #413de8 20.1%, #58b2f4 68.97%),
      #000000;
    border-radius: 16px;
    color: white;
    grid-gap: 10px;

    * {
      color: white;
    }

    > a {
      text-decoration: none;
      color: inherit;
      width: 100%;
      height: 100%;
      display: inline-block;
      vertical-align: middle;
      line-height: 62px;
    }
  }
`;
