import styled from "styled-components";

export const BlockWrapper = styled.div`
  margin: 50px 0;
  text-align: center;
  position: relative;

  .cta-hatch {
    position: relative;
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;
    color: white;
    margin: 16px auto;
    z-index: 1;
    width: fit-content;
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: -1;
    }
  }

  &:has(> img:first-child) {
    h2 {
      padding-top: 0;
    }
  }

  .cloud-left {
    background: radial-gradient(
      106.57% 51.66% at 95.92% 46.6%,
      #f9f6a4 0%,
      #ff6b00 30.91%,
      #362802 100%
    );
    opacity: 0.8;
    filter: blur(50px);
    position: absolute;
    top: 50%;
    transform: translate(-20%, -50%) rotate(180deg);
    left: -16px;
    width: 500px;
    height: 300px;
    z-index: -1;
  }

  .cloud-right {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: radial-gradient(
      106.57% 51.66% at 95.92% 46.6%,
      #f9f6a4 0%,
      #ff6b00 30.91%,
      #362802 100%
    );
    opacity: 0.8;
    filter: blur(40px);
    width: 500px;
    height: 300px;
    z-index: -1;
  }

  h2 {
    border-top: 0;
    color: white;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    margin: 16px 0;
    span {
      display: inline-block;
      position: relative;
      z-index: 1;
      color: white;

      > img {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: -1;
      }
    }
  }

  p {
    margin: 16px 0 24px 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.1px;
    color: rgba(255, 255, 255, 0.85);
  }

  ul {
    margin: 32px 0;
    padding: 0;

    li {
      margin: 16px 0;
      display: grid;
      align-items: center;
      grid-template-columns: 24px 1fr;
      text-align: left;
      grid-gap: 12px;
      counter-increment: counter;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.4px;
      color: rgba(255, 255, 255, 0.85);

      &:before {
        content: "0" counter(counter);
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ff9500;
        background: rgba(255, 149, 0, 0.15);
        border-radius: 6px;
        padding: 4px;
        height: 100%;
        flex: 0 0 24px;
        font-weight: 800;
        font-size: 12px;
        line-height: 12px;
        letter-spacing: 0.1px;
      }
    }
  }
`;

export const Header = styled.div`
  text-align: center;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    right: 0;
    top: 0;
    transform: translate(30%, -10%) rotate(320deg);
    border-radius: 100vmax;
    display: block;
    background: radial-gradient(
      106.57% 51.66% at 95.92% 46.6%,
      #f9f6a4 0%,
      #ff6b00 30.91%,
      #362802 100%
    );
    filter: blur(40px);
    height: 450px;
    width: 580px;
  }
  h1 {
    margin: 0;
    font-weight: 700;
    font-size: 64px;
    line-height: 76px;
    letter-spacing: -0.7px;
    color: #eff4f7;
  }
  p {
    margin: 0;
  }
`;

export const CtaButton = styled.button`
  border: none;
  cursor: pointer;
  margin: 0 auto 50px auto;
  background: #ff9500;
  border-radius: 12px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #000000;
  max-width: 300px;
  width: 100%;
  height: 64px;
  display: flex;

  > * {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
`;

export const Wrapper = styled.main`
  position: relative;
  overflow: hidden;

  .cloud {
    position: absolute;
    z-index: -1;
    background: radial-gradient(
      106.57% 51.66% at 95.92% 46.6%,
      #f9f6a4 0%,
      #ff6b00 30.91%,
      #362802 100%
    );
    opacity: 0.8;
    filter: blur(30px);
    width: 550px;
    height: 350px;
    border-radius: 100vmax;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 50%);
  }
`;
