import styled from "styled-components";
import { BlockWrapper, BlockText } from "../../shared/styled";

export const Header = styled(BlockWrapper)`
  text-align: center;
  position: relative;

  ${BlockText} {
    margin: 0;
  }
`;
