import React, { useMemo } from "react";
import {
  createGlobalStyle,
  ThemeProvider as StyledThemeProvider,
} from "styled-components";
import themes from "../shared/themes";

type Themes = keyof typeof themes;

type ThemeContextProps = {
  theme: Themes;
  updateThemeValue: (theme: Themes) => void;
};

const ThemeContext = React.createContext<ThemeContextProps>({
  theme: "darkOrange",
  updateThemeValue: () => {},
});

const GlobalStyle = createGlobalStyle`
  html {
    background: ${(props) => (props.theme as any).htmlBg} fixed;
  }
  body {
    background: ${(props) => (props.theme as any).bg};
  } 
  a.active{
    color: ${(props) => (props.theme as any).primaryColor}
  }
`;

function ThemeProvider<T extends { children: React.ReactNode }>({
  children,
}: T) {
  const [theme, setTheme] = React.useState<Themes>("darkOrange");

  // React.useEffect(() => {
  //   const cookieThemeValue = getCookie("theme");
  //
  //   if (cookieThemeValue && themes[cookieThemeValue as Themes]) {
  //     setTheme(cookieThemeValue as Themes);
  //   }
  // }, []);

  const value = useMemo(
    () => ({
      theme,
      updateThemeValue: (newThemeValue: Themes) => {
        setTheme(newThemeValue);
        // setCookie("theme", newThemeValue, 30);
      },
    }),
    [theme]
  );

  return (
    <ThemeContext.Provider value={value}>
      <GlobalStyle theme={themes[theme]} />
      <StyledThemeProvider theme={themes[theme]}>
        {children}
      </StyledThemeProvider>
    </ThemeContext.Provider>
  );
}

function useTheme() {
  return React.useContext(ThemeContext);
}

export { ThemeProvider, useTheme, ThemeContext };
