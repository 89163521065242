import React from "react";
import styled from "styled-components";
import close from "../assets/close.svg";
import cat from "../assets/cat.png";
import play from "../assets/play.svg";
import grum from "../assets/grum-2.webp";
import grumpy from "../assets/grumpy-min.webp";
import Button from "../components/Button";
import { useModalControls } from "../shared/hooks";

const Alert = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  padding: 16px;
  background: rgba(255, 255, 255, 0.15);
  border: 1px dashed rgba(255, 255, 255, 0.15);
  border-radius: 6px;
  color: white;
  text-align: center;
`;
const PlayVideoInstructionButton = styled.button`
  cursor: pointer;
  border: none;
  width: 100%;
  margin-bottom: 24px;
  background: #ff9400;
  border-radius: 10px;
  height: 56px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  display: flex;
  align-items: center;
  transition: 0.3s all ease;
  color: black;

  &:active {
    scale: 0.95;
  }

  > img:first-child {
    align-self: flex-end;
    width: 80px;
    height: 80px;
  }

  > img:last-child {
    display: block;
    margin: 0 16px 0 auto;
  }
`;
const ImageWrapper = styled.div`
  max-height: 200px;
  border-radius: 6px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
const Headline = styled.div`
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding: 14px 24px;
  margin: 0 auto;
  width: fit-content;
  border-top: 1px solid #272729;
  border-bottom: 1px solid #272729;
  color: white;
`;
const Subtitle = styled.div`
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 24px;

  span {
    color: rgba(255, 184, 0, 1);
  }
`;
const Title = styled.div`
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  margin-bottom: 4px;
  color: white;
`;
const HeaderImage = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  max-height: 375px;
  width: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;
const VideoWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 80%;
  border-radius: 16px;
  background: #191919;
  overflow: hidden;

  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
  }

  .close_button {
    cursor: pointer;
    z-index: 2;
    margin: 0;
    padding: 0;
    width: 32px;
    height: 32px;
    background: transparent;
    border: none;
    position: absolute;
    right: 6px;
    top: 6px;
  }
`;
const VideoContainer = styled.div`
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
`;
const Wrapper = styled.div`
  position: relative;
  margin: 0 auto;
  padding: 330px 16px 0 16px;
  max-width: 432px;

  > button:last-child {
    margin: 40px 0;
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin: 24px 0;
    padding: 0;
    color: white;
    background: #000;

    a {
      text-decoration: none;
      color: rgba(10, 132, 255, 1);
      font-weight: 600;
      display: inline;
    }
  }
`;

function LongVersion() {
  const { isOpen, openModal, closeModal } = useModalControls(false, {
    disableBodyScroll: true,
  });

  React.useEffect(() => {
    window.ym(93758124, "reachGoal", "show_v2");
  }, []);

  return (
    <Wrapper>
      {isOpen && (
        <VideoContainer>
          <VideoWrapper>
            <button onClick={closeModal} className="close_button">
              <img width={32} height={32} src={close} alt="" />
            </button>
            <iframe
              width={560}
              height={315}
              src="https://www.youtube.com/embed/SH-7FJPXLw4?autoplay=1"
              title="How to get $GRUM"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </VideoWrapper>
        </VideoContainer>
      )}
      <HeaderImage>
        <img src={grum} alt="" />
      </HeaderImage>
      <Title>Meet $GRUM</Title>
      <Subtitle>
        The ultimate <span>Bitcoin</span> meme coin
      </Subtitle>
      <Headline>📢 New Nicegram Partnership 📢</Headline>
      <p>
        🚀 We’re sure you’ve heard of $DOGE, $SHIB, and $PEPE, the meme coins
        that mooned on Ethereum 🚀
      </p>
      <p>
        👀 But did you know there’s meme coins on Bitcoin, too? Because there
        are. And we’re helping you get in early on this new craze by partnering
        with the first genuine meme built on Bitcoin, $GRUM! 👀
      </p>
      <ImageWrapper>
        <img src={grumpy} alt="" />
      </ImageWrapper>
      <p>
        🐾 $GRUM is a fair-launch, community-owned token, with over 2,000 people
        already holding its 21 billion tokens (making it one of the most
        widely-held tokens on Bitcoin, but you’re still very early) 🐾
      </p>
      <p>
        🔥 The team has big plans to list $GRUM on a major exchange soon, so if
        you want to get in on this opportunity (while the price is still low),
        buy it on Unisat now 🔥
      </p>
      <PlayVideoInstructionButton
        onClick={() => {
          openModal();
          window.ym(93758124, "reachGoal", "click_v2_howtobuy");
        }}
      >
        <img width={80} height={80} src={cat} alt="" />
        How To Buy $GRUM
        <img width={24} height={24} src={play} alt="" />
      </PlayVideoInstructionButton>
      <p style={{ paddingTop: 24, borderTop: "1px solid #272729" }}>
        🤫 For information on how to buy and to get an exclusive price (reserved
        for Nicegram users only),{" "}
        <a
          onClick={() => {
            window.ym(93758124, "reachGoal", "click_v2_join");
          }}
          href="https://t.me/grumpynicegram"
        >
          join our group now{" "}
        </a>
        🤫
      </p>
      <Alert>Prepare for the ride of your life 😼💥</Alert>
      <Button
        gradient
        onClick={() => window.ym(93758124, "reachGoal", "click_v2_main")}
        label={
          <a href="ncg://special-offer-click?url=tg://resolve?domain=grumpynicegram">
            GET $GRUM ON SALE
          </a>
        }
      />
    </Wrapper>
  );
}

export default LongVersion;
