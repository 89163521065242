import React from "react";
import styled from "styled-components";
import bg from "../../assets/grumpy-drop-header-bg.png";

const Wrapper = styled.div`
  margin: 0 auto;
  padding: 90px 16px 0 16px;
  max-width: 430px;
  width: 100%;
  position: relative;
  height: 100vh;
  overflow: auto;

  > div {
    position: relative;

    p:last-of-type {
      padding-bottom: 40px;
    }

    > * {
      position: relative;
      z-index: 1;
    }
  }

  h2 {
    text-align: center;
    margin: 0 !important;
    padding: 0;
    border: none;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: -0.2px;
    color: white;
  }

  h1 {
    text-align: center;
    margin: 0 0 90px 0;
    font-size: 72px;
    font-weight: 700;
    line-height: 84px;
    letter-spacing: -1.9px;
    color: white;
  }

  p {
    margin: 24px 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: -0.1px;
    color: white;
  }

  a {
    color: var(--Link-Dark, #0b84ff);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.1px;
    text-decoration: none;
  }

  img {
    position: absolute;
    top: -90px;
    left: -16px;
    width: calc(100% + 32px);
    height: auto;
    z-index: 0;
  }

  .btn {
    position: sticky;
    bottom: 40px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 22px;
    background: rgba(255, 255, 255, 0.1);
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-weight: 600;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.01em;
    color: #ffffff;
    text-decoration: none;
    background: linear-gradient(85.66deg, #af26ed 30.77%, #1d97f3 92.01%);
  }
`;

function GrumAirdrop() {
  React.useEffect(() => {
    if (typeof window !== "undefined")
      window.ym(93758124, "reachGoal", "grumpy_drop_show");
  }, []);

  return (
    <Wrapper>
      <div>
        <img src={bg} alt="" />
        <h2>Random AirDrop of allocations in the amount of</h2>
        <h1>$10.000</h1>
        <p>AirDrop of $10,000 worth of $GRUM from Grumpy BRC-20💰</p>
        <p>
          You are currently enrolled in the $10,000 Airdrop allocation by Grumpy
          BRC-20
        </p>
        <p>
          During the live draw, 3 winners will receive $1000 each, 12 prizes of
          $150 and additional 104 prizes of $50 will be awarded among all
          Airdrop participants 🚀{" "}
          <a
            onClick={() => {
              if (typeof window !== "undefined")
                window.ym(93758124, "reachGoal", "grumpy_drop_cta_click");
            }}
            target="_blank"
            rel="noreferrer nofollow"
            href="https://t.me/GrumpyBRCbot?start=f62f589d-94ed-42c3-ae56-7c5938fd7df7"
          >
            from the general table
          </a>{" "}
        </p>
        <p>HOW TO WIN $10,000:</p>
        <ol>
          <li>
            1. Open and run{" "}
            <a
              onClick={() => {
                if (typeof window !== "undefined")
                  window.ym(93758124, "reachGoal", "grumpy_drop_cta_click");
              }}
              href="https://t.me/GrumpyBRCbot?start=f62f589d-94ed-42c3-ae56-7c5938fd7df7"
            >
              @GrumpyBRCbot
            </a>{" "}
          </li>
          <li>
            2. Subscribe to{" "}
            <a
              onClick={() => {
                if (typeof window !== "undefined")
                  window.ym(93758124, "reachGoal", "grumpy_drop_cta_click");
              }}
              href="https://t.me/GrumpyBRCbot?start=f62f589d-94ed-42c3-ae56-7c5938fd7df7"
            >
              @GrumpyBRC
            </a>{" "}
          </li>
          <li>3. Invite your friends and increase your chance of winning</li>
        </ol>
        <p>We wish good luck to everyone 🚀</p>
        <a
          className="btn"
          onClick={() => {
            if (typeof window !== "undefined")
              window.ym(93758124, "reachGoal", "grumpy_drop_cta_click");
          }}
          target="_blank"
          rel="nofollow noreferrer"
          href="https://t.me/GrumpyBRCbot?start=f62f589d-94ed-42c3-ae56-7c5938fd7df7"
        >
          Win $10 000 in Airdrop
        </a>
      </div>
    </Wrapper>
  );
}

export default GrumAirdrop;
