import React from "react";

function useModalControls(
  initState = false,
  options?: { disableBodyScroll?: boolean }
) {
  const [isOpen, setIsOpen] = React.useState(initState ?? false);

  const openModal = React.useCallback(() => {
    setIsOpen(true);
  }, []);

  const closeModal = React.useCallback(() => {
    setIsOpen(false);
  }, []);

  const disableScrollStyles = React.useMemo(
    () => "overflow:hidden;position:fixed;left:0;right:0;top:0;bottom:0;",
    []
  );

  React.useEffect(
    function disableBodyScroll() {
      if (options?.disableBodyScroll) {
        const escapeKeyListener = (e: KeyboardEvent) =>
          e.code === "Escape" && closeModal();

        if (isOpen) {
          window.addEventListener("keydown", escapeKeyListener);
          document.body.setAttribute(
            "style",
            (document.body.getAttribute("style") ?? "").concat(
              disableScrollStyles
            )
          );
          document.documentElement.setAttribute(
            "style",
            (document.documentElement.getAttribute("style") ?? "").concat(
              "overflow: hidden;"
            )
          );
        } else {
          console.log();

          window.removeEventListener("keydown", escapeKeyListener);
          document.body.setAttribute(
            "style",
            document.body
              .getAttribute("style")
              ?.replace(disableScrollStyles, "") ?? ""
          );
          document.documentElement.setAttribute(
            "style",
            (document.documentElement.getAttribute("style") ?? "").replace(
              "overflow: hidden;",
              ""
            )
          );
        }
      }
    },
    [closeModal, isOpen, options?.disableBodyScroll, disableScrollStyles]
  );

  return {
    isOpen,
    openModal,
    closeModal,
  };
}

export { useModalControls };
