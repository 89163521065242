import React from "react";
import logo from "../assets/logo.png";
import grumpy2 from "../assets/grumpy-nicegram-min.webp";
import Button from "../components/Button";
import Header from "../components/Header";
import HowToBuyGrum from "../components/HowToBuyGrum";
import { ImageWrapper } from "../shared/styled";

function FourthVersion() {
  React.useEffect(() => {
    window.ym(93758124, "reachGoal", "show_v4");
  }, []);

  return (
    <main>
      <Header
        title={
          <div style={{ fontSize: 32, lineHeight: "40px" }}>
            Join the <br />
            <span>$GRUM</span> Revolution!
          </div>
        }
        subtitle={
          <>
            The ultimate <span>Bitcoin</span> meme coin
          </>
        }
      />
      <p>
        Embrace the ultimate Bitcoin memecoin - Grumpy (<span>$GRUM</span>).
        It's time for Bitcoin to reclaim the memecoin throne, leaving all other
        'paw-thetic' clones behind.
      </p>
      <h2>
        <span>$GRUM:</span> Your Ticket to the Moon
      </h2>
      <p>
        With its rapid community growth and ambitious development plans,{" "}
        <span>$GRUM</span>
        is setting the stage to become the crypto universe's next big sensation.
        Seize the unique opportunity to join us before we list on major
        exchanges - a true potential <span>100X coin!</span>
      </p>
      <h2>How to Get GRUM</h2>
      <p>
        Joining the Grumpy revolution is easy! Follow the instructions in our{" "}
        <a href="https://www.youtube.com/watch?v=SH-7FJPXLw4">
          How to Buy $GRUM video
        </a>{" "}
        and secure your <span>$GRUM</span> today.
      </p>
      <p>
        <HowToBuyGrum
          onClick={() => {
            window.ym(93758124, "reachGoal", "click_v4_howtobuy");
          }}
        />
      </p>
      <h2>
        Proud Partner:{" "}
        <img
          style={{ verticalAlign: "middle" }}
          width={22}
          height={22}
          src={logo}
          alt=""
        />{" "}
        Nicegram
      </h2>
      <p>
        We've joined forces with Nicegram, a platform with 2.6 million monthly
        active users, providing exclusive opportunities for early access to{" "}
        <span>$GRUM</span> and an exciting journey towards memecoin dominance.
      </p>
      <p>
        <ImageWrapper>
          <img src={grumpy2} alt="" />
        </ImageWrapper>
      </p>
      <h2>
        Ride the <span>$GRUM</span> Wave
      </h2>
      <p>
        Our future includes listings on top exchanges and a Grumpy-themed game.
        Ride the wave with us - the future is <span>$GRUM</span>. Grab your{" "}
        <span>$GRUM</span> today!
      </p>

      <Button
        onClick={() => window.ym(93758124, "reachGoal", "click_v4_main")}
        style={{ margin: "50px 0" }}
        label={
          <a href="ncg://special-offer-click?url=tg://resolve?domain=grumpynicegram">
            GET $GRUM ON SALE
          </a>
        }
        gradient
      />
    </main>
  );
}

export default FourthVersion;
