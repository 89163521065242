import styled from "styled-components";

export const VideoWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 80%;
  border-radius: 16px;
  background: #191919;
  overflow: hidden;
  z-index: 10;

  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
  }

  .close_button {
    cursor: pointer;
    z-index: 2;
    margin: 0;
    padding: 0;
    width: 32px;
    height: 32px;
    background: transparent;
    border: none;
    position: absolute;
    right: 6px;
    top: 6px;
  }
`;
export const VideoContainer = styled.div`
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 10;
`;

export const Wrapper = styled.button`
  cursor: pointer;
  border: none;
  width: 100%;
  margin-bottom: 24px;
  background: #ff9400;
  border-radius: 10px;
  height: 56px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  display: flex;
  align-items: center;
  transition: 0.3s all ease;
  color: black;

  &:active {
    scale: 0.95;
  }

  > img:first-child {
    align-self: flex-end;
    width: 80px;
    height: 80px;
  }

  > img:last-child {
    display: block;
    margin: 0 16px 0 auto;
  }
`;
