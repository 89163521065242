import React from "react";
import styled from "styled-components";
import grumpy from "../assets/grumpy-no-bg.png";
import play from "../assets/play.svg";
import gradient from "../assets/gradient-min.png";
import { useModalControls } from "../shared/hooks";
import VideoPlayerPopup from "../components/VideoPlayerPopup";

const Alert = styled.div`
  padding: 16px 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.1px;
  color: #ffffff;
  border-radius: 20px;
  background: url(${gradient}) center center no-repeat;
  background-size: cover;

  b {
    color: inherit !important;
  }
`;

const BlockWrapper = styled.div`
  margin: 8px 0;
  padding: 8px 16px;
  text-align: center;
  background: #f5f5f7;
  border-radius: 24px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;

  > span {
    letter-spacing: -0.1px;
    background: linear-gradient(263.36deg, #413de8 20.1%, #58b2f4 68.97%),
      #000000;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  b {
    color: black;
  }

  h1 {
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -0.7px;
    color: #000000;

    span {
      background: linear-gradient(263.36deg, #413de8 20.1%, #58b2f4 68.97%),
        #000000;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }

  h2 {
    border: none;
    margin: 0 0 16px 0;
    font-weight: 800;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: -0.2px;
    background: linear-gradient(263.36deg, #413de8 20.1%, #58b2f4 68.97%),
      #000000;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  h4 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.3);
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.4px;
    color: rgba(0, 0, 0, 0.5);
    margin: 16px 0 0 0;

    &:last-of-type {
      padding-bottom: 32px;
    }
  }

  button {
    border: none;
    cursor: pointer;
    height: 64px;
    width: 100%;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(263.36deg, #413de8 20.1%, #58b2f4 68.97%),
      #000000;
    border-radius: 16px;
    color: white;
    grid-gap: 10px;

    > a {
      text-decoration: none;
      color: inherit;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      grid-gap: 10px;
    }
  }
`;

function EighthVersion() {
  const { isOpen, openModal, closeModal } = useModalControls(false, {
    disableBodyScroll: true,
  });

  React.useLayoutEffect(() => {
    document.body.style.setProperty("background", "white");
    document
      .querySelector('meta[name="theme-color"]')
      ?.setAttribute("content", "#ffffff");

    return () => {
      document.body.style.removeProperty("background");
    };
  }, []);

  React.useEffect(() => {
    window.ym(93758124, "reachGoal", "show_v8");
  }, []);

  return (
    <main>
      {isOpen && (
        <VideoPlayerPopup
          onClose={closeModal}
          src="https://www.youtube.com/embed/SH-7FJPXLw4?autoplay=1"
          iframeTitle="How to get $GRUM"
        />
      )}
      <BlockWrapper>
        📢 <span>New Nicegram Partnership</span> 📢
      </BlockWrapper>
      <BlockWrapper style={{ padding: "20px 16px" }}>
        <img height={180} src={grumpy} alt="" />
        <h1>
          Meet <span>$GRUM</span>
        </h1>
        <p style={{ margin: 0, padding: 0 }}>
          The ultimate <b>Bitcoin</b> meme coin
        </p>
      </BlockWrapper>
      <BlockWrapper>
        <h2>Welcome to the $GRUM Revolution!</h2>
        <p>
          Step into the future with Grumpy <b>($GRUM)</b> - the ultimate Bitcoin
          memecoin. We're clawing our way to the top of the memecoin market,
          leaving the dogs and frogs in the dust.
        </p>
      </BlockWrapper>
      <BlockWrapper>
        <h2>
          $GRUM: The Potential <br /> 100X Coin
        </h2>
        <p>
          With a rapidly expanding community and ambitious future plans,{" "}
          <b>$GRUM</b> is setting the stage to become the next sensation in the
          crypto world. We're building on the powerful BRC20 protocol and
          getting ready for lift-off. Here's your chance to join before{" "}
          <b>$GRUM</b> makes its debut on major exchanges.
        </p>
      </BlockWrapper>
      <BlockWrapper>
        <h2>
          Get $GRUM Now Before <br /> It's Too Late!
        </h2>
        <p>
          With a rapidly expanding community and ambitious future plans, $GRUM
          is setting the stage to become the next sensation in the crypto world.
          We're building on the powerful BRC20 protocol and getting ready for
          lift-off. Here's your chance to join before $GRUM makes its debut on
          major exchanges.
        </p>
        <button
          style={{ fontSize: 16, fontWeight: 600 }}
          onClick={() => {
            openModal();
            window.ym(93758124, "reachGoal", "click_v8_howtobuy");
          }}
        >
          <img style={{ filter: "invert(1)" }} src={play} alt="" />
          How To Buy $GRUM
        </button>
      </BlockWrapper>
      <BlockWrapper>
        <h2>
          Our Partnership <br />
          with Nicegram
        </h2>
        <p>
          We're excited to announce our partnership with Nicegram, providing 2.6
          million monthly users exclusive early access to <b>$GRUM</b>. Nicegram
          users, this is your opportunity to get in on the ground floor!
        </p>
        <p>
          We've joined forces with Nicegram, a platform with 2.6 million monthly
          active users, providing exclusive opportunities for early access to{" "}
          <b>$GRUM</b> and an exciting journey towards memecoin dominance.
        </p>
      </BlockWrapper>
      <BlockWrapper>
        <h2>
          The Future is Bright <br /> with $GRUM
        </h2>
        <p>
          We have exciting things coming down the pipeline: listings on top
          exchanges, a Grumpy-themed game, and more. But these future
          developments also mean potential price increases.
        </p>
        <Alert>
          Get your <b>$GRUM</b> today and lock in your position before lift-off!
        </Alert>
      </BlockWrapper>
      <BlockWrapper style={{ marginBottom: 50 }}>
        <h2>
          Last Chance: <br />
          Get $GRUM Now!
        </h2>
        <p>
          This is your moment. When <b>$GRUM</b> hits the exchanges, we're
          expecting prices to climb. Join the revolution now or watch from the
          sidelines as others reach for the stars. The future is <b>$GRUM</b>.
          Secure your <b>$GRUM</b> today!
        </p>
        <button
          onClick={() => window.ym(93758124, "reachGoal", "click_v8_main")}
        >
          <a href="ncg://special-offer-click?url=tg://resolve?domain=grumpynicegram">
            GET $GRUM ON SALE
          </a>
        </button>
      </BlockWrapper>
    </main>
  );
}

export default EighthVersion;
