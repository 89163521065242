import React from "react";
import { useTranslation } from "react-i18next";
import { useOutsideClick } from "../../shared/utils/hooks";
import { LANGUAGE_OPTIONS } from "../../shared/utils/constants";
import { setCookie } from "../../shared/utils/helpers";
import {
  ActiveLanguage,
  LanguageMenuItem,
  LanguageMenuList,
  Wrapper,
} from "./styled";
import { useModalControls } from "../../shared/hooks";

function LanguageMenu() {
  const { i18n } = useTranslation();
  const [activeLanguageOption, setActiveLanguageOption] = React.useState(
    LANGUAGE_OPTIONS.find((el) => el.value === i18n.language.slice(0, 2)) ??
      LANGUAGE_OPTIONS[0]
  );
  const {
    isOpen: isLanguageMenuOpen,
    openModal: openLanguageMenu,
    closeModal: closeLanguageMenu,
  } = useModalControls(false, { disableBodyScroll: true });

  const containerRef = React.useRef<HTMLUListElement>(null);

  const handleOutsideClick = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if ((event as any).target?.id === "language") {
        return;
      }

      closeLanguageMenu();
    },
    [closeLanguageMenu]
  );

  useOutsideClick(containerRef, handleOutsideClick);

  return (
    <Wrapper>
      <ActiveLanguage onClick={openLanguageMenu}>
        <div>
          <img
            width={32}
            height={32}
            src={activeLanguageOption.img}
            alt="active language option"
          />
        </div>
      </ActiveLanguage>
      <LanguageMenuList ref={containerRef} show={isLanguageMenuOpen}>
        {LANGUAGE_OPTIONS.map((lang) => (
          <LanguageMenuItem
            key={lang.value}
            active={i18n.language.startsWith(lang.value)}
          >
            <button
              type="button"
              onClick={() => {
                setActiveLanguageOption(lang);
                void i18n.changeLanguage(lang.value);
                setCookie("lang", lang.value, 30);
                closeLanguageMenu();
              }}
            >
              <div>
                <img src={lang.img} alt={lang.label} />
              </div>
              <div>
                <div>{lang.label}</div>
                <div>{lang.country}</div>
              </div>
            </button>
          </LanguageMenuItem>
        ))}
      </LanguageMenuList>
    </Wrapper>
  );
}

export { LanguageMenu };
