import React from "react";
import logo from "../assets/logo.png";
import grumpy2 from "../assets/grumpy-nicegram-min.webp";
import Button from "../components/Button";
import Header from "../components/Header";
import HowToBuyGrum from "../components/HowToBuyGrum";
import { Alert, ImageWrapper } from "../shared/styled";

function FifthVersion() {
  React.useEffect(() => {
    window.ym(93758124, "reachGoal", "show_v5");
  }, []);

  return (
    <main>
      <Header
        title={
          <>
            Meet <span>$GRUM</span>
          </>
        }
        subtitle={
          <>
            The ultimate <span>Bitcoin</span> meme coin
          </>
        }
        headline="📢 New Nicegram Partnership 📢"
      />
      <h2 style={{ borderTop: "none", paddingTop: 0 }}>
        Welcome to the <br /> <span>$GRUM</span> Revolution!
      </h2>

      <p>
        Step into the future with Grumpy (<span>$GRUM</span>) - the ultimate
        Bitcoin memecoin. We're clawing our way to the top of the memecoin
        market, leaving the dogs and frogs in the dust.
      </p>
      <h2>
        <span>$GRUM</span>: The Potential 100X Coin
      </h2>
      <p>
        With a rapidly expanding community and ambitious future plans,{" "}
        <span>$GRUM</span> is setting the stage to become the next sensation in
        the crypto world. We're building on the powerful <span>BRC20</span>{" "}
        protocol and getting ready for lift-off. Here's your chance to join
        before <span>$GRUM</span> makes its debut on major exchanges.
      </p>
      <h2>
        Get <span>$GRUM</span> Now Before It's Too Late!
      </h2>

      <p>
        It's easy to join the Grumpy revolution! Just follow the steps in our{" "}
        <span>How to Buy $GRUM video</span> and become a part of the $GRUM
        movement today. Hurry, because once we're on the exchanges, prices are
        expected to skyrocket!
      </p>
      <p>
        <HowToBuyGrum
          onClick={() => {
            window.ym(93758124, "reachGoal", "click_v5_howtobuy");
          }}
        />
      </p>
      <h2>
        Our Proud Partnership <br />
        with{" "}
        <img
          style={{ verticalAlign: "middle" }}
          width={22}
          height={22}
          src={logo}
          alt=""
        />{" "}
        Nicegram
      </h2>
      <p>
        We're excited to announce our partnership with Nicegram, providing 2.6
        million monthly users exclusive early access to <span>$GRUM</span>.
        Nicegram users, this is your opportunity to get in on the ground floor!
      </p>
      <p>
        We've joined forces with Nicegram, a platform with 2.6 million monthly
        active users, providing exclusive opportunities for early access to{" "}
        <span>$GRUM</span> and an exciting journey towards memecoin dominance.
      </p>
      <p>
        <ImageWrapper>
          <img src={grumpy2} alt="" />
        </ImageWrapper>
      </p>
      <h2>
        The Future is Bright <br /> with <span>$GRUM</span>
      </h2>
      <p>
        We have exciting things coming down the pipeline: listings on top
        exchanges, a Grumpy-themed game, and more. But these future developments
        also mean potential price increases.
      </p>
      <Alert>
        Get your <span>$GRUM</span> today and lock in your position before
        lift-off!
      </Alert>
      <h2>
        Last Chance: <span>Get $GRUM Now!</span>
      </h2>
      <p>
        This is your moment. When <span>$GRUM</span> hits the exchanges, we're
        expecting prices to climb. Join the revolution now or watch from the
        sidelines as others reach for the stars. The future is{" "}
        <span>$GRUM</span>. Secure your <span>$GRUM</span> today!
      </p>
      <Button
        onClick={() => window.ym(93758124, "reachGoal", "click_v5_main")}
        style={{ margin: "50px 0" }}
        label={
          <a href="ncg://special-offer-click?url=tg://resolve?domain=grumpynicegram">
            GET $GRUM ON SALE
          </a>
        }
        gradient
      />
    </main>
  );
}

export default FifthVersion;
