import React from "react";
import grumpy from "../../assets/grumpy-no-bg.png";
import rocket from "./assets/rocket-min.png";
import nicegram from "./assets/nicegram-logo-min.png";
import hatch from "./assets/hatch.png";
import chart from "./assets/chart-v13-min.png";
import { Card } from "../../shared/styled";
import { Wrapper } from "./styled";
import LanguageMenu from "../../features/LanguageMenu";
import { Trans, useTranslation } from "react-i18next";

function Index() {
  const { t } = useTranslation("v13");
  React.useLayoutEffect(() => {
    document.body.style.setProperty("background", "white");
    document
      .querySelector('meta[name="theme-color"]')
      ?.setAttribute("content", "#ffffff");

    return () => {
      document.body.style.removeProperty("background");
    };
  }, []);

  React.useEffect(() => {
    window.ym(93758124, "reachGoal", "show_v13");
  }, []);

  return (
    <Wrapper>
      <Card style={{ padding: "20px 16px" }}>
        <div
          style={{
            width: "100%",
            height: 0,
            position: "relative",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <LanguageMenu />
        </div>
        <img height={180} src={grumpy} alt="" />
        <h1>{t("title")}</h1>
        <p style={{ margin: 0, padding: 0 }}>
          <Trans i18nKey="subtitle" components={{ b: <b /> }} />
        </p>
      </Card>
      <Card style={{ padding: "16px 8px" }}>
        <img
          width={340}
          height={230}
          style={{ width: "100%", height: "auto" }}
          src={chart}
          alt=""
        />
        <h2>
          <Trans
            i18nKey="dont_miss_grum"
            components={{
              hatch: <img src={hatch} alt="" />,
              span: <span />,
            }}
          />
        </h2>
      </Card>
      <Card>
        <img height={180} src={rocket} alt="" />
        <h2>
          <Trans
            i18nKey="time_is_now"
            components={{
              hatch: <img src={hatch} alt="" />,
              span: <span />,
            }}
          />
        </h2>
        <p>
          <Trans
            i18nKey="time_is_now_text"
            components={{
              b: <b />,
            }}
          />
        </p>
      </Card>
      <Card>
        <img height={140} src={nicegram} alt="" />
        <h2>{t("supported_by_nicegram")}</h2>
        <p>
          <Trans
            i18nKey="supported_by_nicegram_text"
            components={{
              b: <b />,
            }}
          />
        </p>
      </Card>
      <Card>
        <h2>{t("take_action_now")}</h2>
        <ul>
          <Trans
            i18nKey="take_action_now_text"
            components={{
              li: <li />,
              b: <b />,
            }}
          />
        </ul>
        <div className="cta-hatch">
          <Trans
            i18nKey="dont_wait"
            components={{
              hatch: <img src={hatch} alt="" />,
              span: <span />,
            }}
          />
        </div>
        <button
          onClick={() => window.ym(93758124, "reachGoal", "click_v13_main")}
        >
          <a href="ncg://special-offer-click?url=tg://resolve?domain=grumpynicegram">
            <Trans
              i18nKey="secure_your_grum"
              components={{
                b: <b />,
              }}
            />
          </a>
        </button>
      </Card>
    </Wrapper>
  );
}

export default Index;
