import React from "react";
import logo from "../assets/logo.png";
import grumpy from "../assets/grumpy-min.webp";
import grumpy2 from "../assets/grumpy-nicegram-min.webp";
import Button from "../components/Button";
import Header from "../components/Header";
import HowToBuyGrum from "../components/HowToBuyGrum";
import { Alert, ImageWrapper } from "../shared/styled";

function ThirdVersion() {
  React.useEffect(() => {
    window.ym(93758124, "reachGoal", "show_v3");
  }, []);

  return (
    <main>
      <Header
        tagline="Welcome to the"
        title={
          <>
            World of <span>$GRUM</span>!
          </>
        }
        subtitle={
          <>
            The ultimate <span>Bitcoin</span> meme coin
          </>
        }
      />
      <p>
        Prepare for the ride of your life with Grumpy (<span>$GRUM</span>) - the
        ultimate Bitcoin memecoin. Ready to pounce on the crypto market, we're
        clawing our way to the memecoin throne, leaving the dogs and frogs
        behind.
      </p>
      <h2>
        Why Bitcoin? Why <span>$GRUM</span>?
      </h2>
      <p>
        While the crypto world was filled with 'paw-thetic' clones, we dared to
        be different. Built on Bitcoin, the king of all cryptocurrencies,{" "}
        <span>$GRUM</span> is primed to make its mark. It's the memecoin
        revolution Bitcoiners have been waiting for. Now it’s time for Bitcoin
        to reclaim the memecoin throne, with Grumpy leading the charge.
      </p>
      <p>
        <ImageWrapper>
          <img src={grumpy} alt="" />
        </ImageWrapper>
      </p>
      <h2>
        <span>$GRUM:</span> The Next 100X Coin
      </h2>
      <p>
        With a rapidly growing community and ambitious plans for future
        developments, <span>$GRUM</span> is setting up to become the next big
        thing in the crypto universe. Building on the robust{" "}
        <span style={{ color: "white" }}>
          <b>BRC20</b>
        </span>{" "}
        protocol and bolstered by an active community, <span>$GRUM</span> is
        ready to ascend.
        <br />
        <br />
        We're not just riding the waves, we're creating them. Early investors
        have the unique opportunity to partake in the surge before{" "}
        <span>$GRUM</span> gets listed on major exchanges.
      </p>
      <h2>
        Get <span>$GRUM</span> Today and Grow with Us!
      </h2>
      <p>
        Follow these simple steps to join the Grumpy revolution:
        <ul>
          <li>
            Visit <a href="https://unisat.io/">Unisat</a> and download the
            Chrome extension. Create a new wallet and secure it with a password.
          </li>
          <li>
            Save your seed phrase and choose{" "}
            <span>Taproot (P2TR) (m/86'/0'/0'/0/0)</span>.
          </li>
          <li>Click 'Receive' and copy your personal address.</li>
          <li>Send Bitcoin (BTC) to your wallet (via the Bitcoin network).</li>
          <li>
            Go to the Unisat marketplace at{" "}
            <a href="https://unisat.io/market?tick=grum">Unisat GRUM Market.</a>
          </li>
        </ul>
      </p>
      <p>
        <HowToBuyGrum
          onClick={() => {
            window.ym(93758124, "reachGoal", "click_v3_howtobuy");
          }}
        />
      </p>
      <h2>
        Our Proud Partnership <br />
        with{" "}
        <img
          style={{ verticalAlign: "middle" }}
          width={22}
          height={22}
          src={logo}
          alt=""
        />{" "}
        Nicegram
      </h2>
      <p>
        We are thrilled to announce our partnership with Nicegram, bringing
        exclusive benefits and opportunities to over 2.6 million monthly active
        users. Get early access to <span>$GRUM</span> and become part of the
        exciting journey to the top of the memecoin market.
      </p>
      <p>
        <ImageWrapper>
          <img src={grumpy2} alt="" />
        </ImageWrapper>
      </p>
      <h2>
        The Future is <span>$GRUM</span>
      </h2>
      <p>
        Our roadmap is paved with exciting developments, including listings on
        top <span>DEX</span> and <span>CEX</span> exchanges, and the
        introduction of a Grumpy-themed game.
      </p>
      <Alert>
        Ride the wave with us. The future is <span>$GRUM</span>. Get your{" "}
        <span>$GRUM</span> today!
      </Alert>
      <Button
        onClick={() => window.ym(93758124, "reachGoal", "click_v3_main")}
        style={{ margin: "50px 0" }}
        label={
          <a href="ncg://special-offer-click?url=tg://resolve?domain=grumpynicegram">
            GET $GRUM ON SALE
          </a>
        }
        gradient
      />
    </main>
  );
}

export default ThirdVersion;
