import React from "react";

/**
 * @param {React.RefObject<any>} elementRef - ref object
 * @param {function} onOutsideClick - callback function
 *
 * This hook is used to detect clicks outside a given element.
 *
 * ### Example
 * ```jsx
 *   const ref = React.useRef<HTMLDivElement | null>(null);
 *   const callbackFunc = () => {};
 *   useOutsideClick(ref, callbackFunc);
 * ```
 */
function useOutsideClick(
  elementRef: React.RefObject<any>,
  onOutsideClick: (event: any) => void
) {
  React.useEffect(() => {
    function handleClickOutside(event: Event) {
      if (elementRef.current && !elementRef.current.contains(event.target)) {
        onOutsideClick(event);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [elementRef, onOutsideClick]);
}

export { useOutsideClick };
