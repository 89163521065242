import React from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import grum from "../assets/grum.webp";
import Button from "../components/Button";

const Alert = styled(motion.div)`
  padding: 16px;
  width: 100%;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.65);
  background: rgba(255, 255, 255, 0.15);
  border: 1px dashed rgba(57, 65, 71, 0.15);
  border-radius: 6px;
`;
const Subtitle = styled(motion.div)`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: rgba(19, 20, 23, 0.65);
  margin-top: 4px;
`;
const Title = styled(motion.h1)`
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: black;
  margin: 35px 0 0 0;
`;

const ImageWrapper = styled(motion.div)`
  flex: 1;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  max-height: 350px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    display: block;
  }
`;

const Content = styled(motion.div)`
  max-width: 532px;
  margin: 0 auto;
  padding: 0 16px;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  flex: 1;

  p {
    margin: 24px 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: rgba(0, 0, 0, 1);
  }

  button {
    margin-top: 35px;
    margin-bottom: 40px;
  }
`;

const Wrapper = styled(motion.div)`
  background: rgba(255, 148, 0, 1);
  height: var(--app-height);
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const containerVariants = {
  hidden: {
    opacity: 0,
    transition: {
      when: "afterChildren",
      staggerChildren: 0.07,
      staggerDirection: -1,
      duration: 0.2,
    },
  },
  show: {
    opacity: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.1,
      duration: 0.1,
    },
  },
};

function ShortVersion() {
  React.useEffect(() => {
    window.ym(93758124, "reachGoal", "show_v1");
  }, []);

  return (
    <Wrapper initial="hidden" animate="show" variants={containerVariants}>
      <Content>
        <ImageWrapper>
          <img src={grum} alt="" />
        </ImageWrapper>
        <Title>Meet $GRUM</Title>
        <Subtitle>The ultimate Bitcoin meme coin</Subtitle>
        <motion.p>
          🤫For information on how to buy and to get an exclusive
          price,(reserved for Nicegram users only) join our group now 🤫
        </motion.p>
        <Alert>Prepare for the ride of your life 😼💥</Alert>
        <Button
          onClick={() => window.ym(93758124, "reachGoal", "click_v1_main")}
          label={
            <a href="ncg://special-offer-click?url=tg://resolve?domain=grumpynicegram">
              GET $GRUM ON SALE
            </a>
          }
        />
      </Content>
    </Wrapper>
  );
}

export default ShortVersion;
