import styled from "styled-components";
import { Card } from "../../shared/styled";

export const Wrapper = styled.main`
  padding-bottom: 50px;

  .cta-hatch {
    position: relative;
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;
    color: white;
    margin: 16px auto;
    z-index: 1;
    width: fit-content;
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: -1;
    }
  }
  ${Card} {
    h1 {
      font-weight: 700;
      font-size: 64px;
      line-height: 76px;
      background: linear-gradient(263.36deg, #413de8 20.1%, #58b2f4 68.97%),
        #000000;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
    h2 {
      font-weight: 800;
      font-size: 32px;
      line-height: 40px;

      span {
        position: relative;
        color: white;
        -webkit-background-clip: border-box;
        -webkit-text-fill-color: currentcolor;
        background-clip: border-box;
        text-fill-color: currentcolor;
        display: inline-flex;
        z-index: 1;

        > img {
          position: absolute;
          width: 100%;
          height: 100%;
          z-index: -1;
        }
      }
    }
    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.55);
    }

    button {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }
  }
`;
