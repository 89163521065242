import { motion } from "framer-motion";
import styled, { css } from "styled-components";

export const Wrapper = styled(motion.button)<{
  $gradient?: boolean;
  bouncing?: boolean;
}>`
  position: relative;
  z-index: 5;
  overflow: hidden;
  width: 100%;
  height: 50px;
  flex: 0 0 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 1);
  border: none;
  border-radius: 6px;
  cursor: pointer;
  letter-spacing: 0.1px;
  color: rgba(255, 255, 255, 1);
  font-weight: 800;
  font-size: 14px;
  line-height: 20px;
  transition: 0.3s all ease;

  > a {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    text-decoration: none;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    color: inherit;
    display: inherit;
    justify-content: inherit;
    align-items: inherit;
  }

  &:active {
    scale: 0.95;
  }

  ${(props) =>
    props.$gradient &&
    css`
      background: linear-gradient(85.66deg, #af26ed 30.77%, #1d97f3 92.01%);
    `}
`;
