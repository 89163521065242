import React from "react";
import grumpy from "../../assets/grumpy-no-bg.png";
import CtaButton from "../../features/CtaButton";
import {
  Container,
  BlockWrapper,
  BlockTitle,
  BlockText,
} from "../../shared/styled";
import ss from "./assets/ss-min.webp";
import btc from "./assets/btc-min.webp";
import bitmart from "./assets/bitmart-min.webp";
import { Header } from "./styled";
import { useTheme } from "../../context/ThemeContext";

function Index() {
  const { updateThemeValue } = useTheme();

  React.useEffect(() => {
    updateThemeValue("lightBlue");
    window.ym(93758124, "reachGoal", "show_v18");
  }, []);

  return (
    <Container>
      <Header style={{ padding: "20px 16px" }}>
        <img height={250} src={grumpy} alt="" />
        <BlockTitle>Grumpy</BlockTitle>
        <BlockText>
          The first-ever meme coin on the <br /> Bitcoin network
        </BlockText>{" "}
        <BlockText>
          Don’t miss out on this unique <br /> opportunity. Buy the token easily
          on <br /> the well known exchange Bitmart — <br /> with USDT, Bitcoin
          or deposit first <br /> with your card.
        </BlockText>
      </Header>
      <BlockWrapper>
        <img height={180} src={ss} alt="" />
        <BlockTitle>it's a Revolution!</BlockTitle>
        <BlockText>
          GRUM isn't just another token - it's a revolution! Say goodbye to dogs
          and frogs on copycat networks.
        </BlockText>
      </BlockWrapper>{" "}
      <BlockWrapper>
        <img height={180} src={btc} alt="" />
        <BlockTitle>
          Built on the <br /> BRC20 (Bitcoin){" "}
        </BlockTitle>
        <BlockText>
          Built on the BRC20 (Bitcoin) protocol, $GRUM is set to skyrocket on
          one of the fastest-growing protocols in the crypto world.
        </BlockText>
      </BlockWrapper>{" "}
      <BlockWrapper>
        <img height={90} src={bitmart} alt="" />
        <BlockTitle>Don’t miss out on this unique opportunity.</BlockTitle>
        <BlockText>
          Buy the token easily on the well known exchange Bitmart — with USDT,
          Bitcoin or deposit first with your card.
        </BlockText>
        <CtaButton
          href="ncg://special-offer-click?url=https://www.bitmart.com/register-referral/en?r=VecjGg"
          onClick={() => window.ym(93758124, "reachGoal", "click_v18_main")}
        >
          BUY $GRUM ON BITMART EXCHANGE TODAY
        </CtaButton>
      </BlockWrapper>
    </Container>
  );
}

export default Index;
