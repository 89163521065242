import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import v13en from "./translations/v13/en.json";
import v13ar from "./translations/v13/ar.json";
import v13bd from "./translations/v13/bd.json";
import v13cn from "./translations/v13/cn.json";
import v13de from "./translations/v13/de.json";
import v13es from "./translations/v13/es.json";
import v13ha from "./translations/v13/ha.json";
import v13hk from "./translations/v13/hk.json";
import v13it from "./translations/v13/it.json";
import v13ka from "./translations/v13/ka.json";
import v13ms from "./translations/v13/ms.json";
import v13ph from "./translations/v13/ph.json";
import v13pt from "./translations/v13/pt.json";
import v13ur from "./translations/v13/ur.json";
import v13vi from "./translations/v13/vi.json";

const IS_PRODUCTION_BUILD = process.env.NODE_ENV === "production";

const resources = {
  pt: {
    v13: v13pt,
  },
  ur: {
    v13: v13ur,
  },
  vi: {
    v13: v13vi,
  },
  ha: {
    v13: v13ha,
  },
  hk: {
    v13: v13hk,
  },
  it: {
    v13: v13it,
  },
  ka: {
    v13: v13ka,
  },
  ms: {
    v13: v13ms,
  },
  ph: {
    v13: v13ph,
  },
  en: {
    v13: v13en,
  },
  ar: {
    v13: v13ar,
  },
  bd: {
    v13: v13bd,
  },
  cn: {
    v13: v13cn,
  },
  de: {
    v13: v13de,
  },
  es: {
    v13: v13es,
  },
};

void i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    defaultNS: "v13",
    supportedLngs: Object.keys(resources),
    resources,
    fallbackLng: ["en"],
    debug: !IS_PRODUCTION_BUILD,
    detection: {
      order: ["querystring", "cookie", "localStorage", "navigator"],
      lookupQuerystring: "lang",
      lookupCookie: "lang",
    },
    interpolation: {
      escapeValue: false,
    },
  });

const { t } = i18n;

export { t };
export default i18n;
