import React from "react";
import { useDebounce } from "use-debounce";
import { Navigate, Route, Routes } from "react-router-dom";
import {
  FourthVersion,
  LongVersion,
  ShortVersion,
  ThirdVersion,
  FifthVersion,
  SixthVersion,
  EighthVersion,
  NinthVersion,
  TenthVersion,
  EleventhVersion,
  ThirteenthVersion,
  FourteenthVersion,
  FifteenthVersion,
  SixteenthVersion,
  SeventeenthVersion,
  EighteenthVersion,
} from "./pages";
import { ThemeProvider } from "./context/ThemeContext";
import NineteenthVersion from "./pages/NineteenthVersion";

function App() {
  const [height, setHeight] = React.useState(window.innerHeight);
  const [debouncedValue] = useDebounce(height, 250);

  React.useEffect(() => {
    const appHeight = () => {
      setHeight(window.innerHeight);
    };

    window.addEventListener("resize", appHeight);
    appHeight();
  }, [setHeight]);

  React.useEffect(() => {
    const doc = document.documentElement;

    doc.style.setProperty("--app-height", `${debouncedValue}px`);
  }, [debouncedValue]);

  return (
    <ThemeProvider>
      <Routes>
        <Route path="/v1" element={<ShortVersion />} />
        <Route path="/v2" element={<LongVersion />} />
        <Route path="/v3" element={<ThirdVersion />} />
        <Route path="/v4" element={<FourthVersion />} />
        <Route path="/v5" element={<FifthVersion />} />
        <Route path="/v6" element={<SixthVersion />} />
        <Route path="/v8" element={<EighthVersion />} />
        <Route path="/v9" element={<NinthVersion />} />
        <Route path="/v10" element={<TenthVersion />} />
        <Route path="/v11" element={<EleventhVersion />} />
        <Route path="/v13" element={<ThirteenthVersion />} />
        <Route path="/v14" element={<FourteenthVersion />} />
        <Route path="/v15" element={<FifteenthVersion />} />
        <Route path="/v16" element={<SixteenthVersion />} />
        <Route path="/v17" element={<SeventeenthVersion />} />
        <Route path="/v18" element={<EighteenthVersion />} />
        <Route path="/drop" element={<NineteenthVersion />} />
        <Route path="*" element={<Navigate to="/v1" replace />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
