import React from "react";
import grumpy from "../../assets/grumpy-no-bg.png";
import CtaButton from "../../features/CtaButton";
import {
  Container,
  BlockWrapper,
  BlockTitle,
  BlockText,
} from "../../shared/styled";
import clock from "./assets/clock-min.webp";
import hatch from "./assets/hatch.png";
import { Header } from "./styled";
import { useTheme } from "../../context/ThemeContext";

function Index() {
  const { updateThemeValue } = useTheme();

  React.useEffect(() => {
    updateThemeValue("lightBlue");
    window.ym(93758124, "reachGoal", "show_v16");
  }, []);

  return (
    <Container>
      <Header style={{ padding: "20px 16px" }}>
        <img height={250} src={grumpy} alt="" />
        <BlockTitle>BREAKING!</BlockTitle>
        <BlockText>
          Ordinals (<b>brc20</b>) token just got <br /> listed on Binance
        </BlockText>
      </Header>
      <BlockWrapper>
        <img height={180} src={clock} alt="" />
        <BlockTitle>
          The best time to buy <br /> $GRUM (brc20) <br /> is{" "}
          <span>
            <img src={hatch} alt="" /> Now...
          </span>
        </BlockTitle>
        <BlockText>Before the Binance listing</BlockText>
        <CtaButton
          href="ncg://special-offer-click?url=https://www.bitmart.com/register-referral/en?r=VecjGg"
          onClick={() => window.ym(93758124, "reachGoal", "click_v16_main")}
        >
          BUY ON BITMART TODAY
        </CtaButton>
      </BlockWrapper>
    </Container>
  );
}

export default Index;
