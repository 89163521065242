import React from "react";
import close from "../../assets/close.svg";
import { Wrapper, VideoWrapper } from "./styled";

type VideoPlayerPopupProps = {
  onClose?: () => void;
  src?: string;
  iframeTitle?: string;
};

function VideoPlayerPopup({
  onClose,
  src,
  iframeTitle,
}: VideoPlayerPopupProps) {
  return (
    <Wrapper>
      <VideoWrapper>
        <button onClick={onClose} className="close_button">
          <img width={32} height={32} src={close} alt="" />
        </button>
        <iframe
          width={560}
          height={315}
          src={src}
          title={iframeTitle}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </VideoWrapper>
    </Wrapper>
  );
}

export { VideoPlayerPopup, type VideoPlayerPopupProps };
