import React from "react";
import grumpy from "../../assets/grumpy-no-bg.png";
import CtaButton from "../../features/CtaButton";
import {
  Container,
  BlockWrapper,
  BlockTitle,
  BlockText,
} from "../../shared/styled";
import rocket from "./assets/rocket-min.webp";
import hatch from "./assets/hatch.png";
import { Header } from "./styled";
import { useTheme } from "../../context/ThemeContext";

function Index() {
  const { updateThemeValue } = useTheme();

  React.useEffect(() => {
    updateThemeValue("lightBlue");
    window.ym(93758124, "reachGoal", "show_v17");
  }, []);

  return (
    <Container>
      <Header style={{ padding: "20px 16px" }}>
        <img height={250} src={grumpy} alt="" />
        <BlockTitle>Grumpy is back!</BlockTitle>
        <BlockText>
          The first-ever meme coin on the Bitcoin <br /> network just got listed
          on Bitmart
        </BlockText>
      </Header>
      <BlockWrapper>
        <img height={180} src={rocket} alt="" />
        <BlockTitle>
          BRC20 is{" "}
          <span>
            <img src={hatch} alt="" /> Alive
          </span>
        </BlockTitle>
        <BlockText>
          <b>$GRUM</b> goes to the moon! 
        </BlockText>
        <CtaButton
          href="ncg://special-offer-click?url=https://www.bitmart.com/register-referral/en?r=VecjGg"
          onClick={() => window.ym(93758124, "reachGoal", "click_v17_main")}
        >
          BUY YOU BAG ON BITMART TODAY
        </CtaButton>
      </BlockWrapper>
    </Container>
  );
}

export default Index;
