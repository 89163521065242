import React from "react";
import styled from "styled-components";
import grumpy from "../assets/grumpy-no-bg.png";
import cat from "../assets/cat.png";
import play from "../assets/play.svg";
import logo from "../assets/logo.png";
import {
  VideoContainer,
  VideoWrapper,
} from "../components/HowToBuyGrum/styled";
import close from "../assets/close.svg";
import { useModalControls } from "../shared/hooks";

const HowToBuyGrum = styled.button`
  margin: 0 auto;
  max-width: 300px;
  width: 100%;
  background: radial-gradient(
    124.66% 568.75% at -8.56% 128.13%,
    #f9f6a4 0%,
    #ff6b00 18.76%,
    #362802 100%
  );
  border-radius: 10px;
  height: 64px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #ffffff;
  display: flex;
  align-items: center;
  border: none;

  > img {
    align-self: flex-end;
    width: 80px;
    height: 80px;
  }

  > div {
    display: flex;
    align-items: center;
    grid-gap: 8px;
    padding: 16px 16px 16px 0;

    > div {
      white-space: nowrap;
    }
    span {
      color: #ff9500;
    }
    img {
      filter: invert(1);
    }
  }
`;

const BlockWrapper = styled.div`
  margin: 50px 0;
  text-align: center;
  position: relative;

  .cloud-left {
    background: radial-gradient(
      106.57% 51.66% at 95.92% 46.6%,
      #f9f6a4 0%,
      #ff6b00 30.91%,
      #362802 100%
    );
    opacity: 0.8;
    filter: blur(50px);
    position: absolute;
    top: 50%;
    transform: translate(-20%, -50%) rotate(180deg);
    left: -16px;
    width: 500px;
    height: 300px;
    z-index: -1;
  }

  .cloud-right {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: radial-gradient(
      106.57% 51.66% at 95.92% 46.6%,
      #f9f6a4 0%,
      #ff6b00 30.91%,
      #362802 100%
    );
    opacity: 0.8;
    filter: blur(40px);
    width: 500px;
    height: 300px;
    z-index: -1;
  }

  h2 {
    border-top: 0;
    margin: 16px 0;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    letter-spacing: -0.2px;
    color: #ffffff;
    padding: 0;
  }

  p {
    margin: 16px 0 24px 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: rgba(255, 255, 255, 0.8);
  }
`;

const Alert = styled.div`
  background: rgba(255, 255, 255, 0.07);
  border-radius: 25px;
  padding: 16px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.1px;
  color: #ffffff;
  span {
    color: #ff9500;
  }
`;

const Header = styled.div`
  text-align: center;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    right: 0;
    top: 0;
    transform: translate(30%, -10%) rotate(320deg);
    border-radius: 100vmax;
    display: block;
    background: radial-gradient(
      106.57% 51.66% at 95.92% 46.6%,
      #f9f6a4 0%,
      #ff6b00 30.91%,
      #362802 100%
    );
    filter: blur(40px);
    height: 450px;
    width: 580px;
  }
  h1 {
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -0.7px;
    color: #eff4f7;
  }

  h5 {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.1px;
    color: #ffffff;
  }
`;

const CtaButton = styled.button`
  border: none;
  cursor: pointer;
  margin: 50px auto;
  background: #ff9500;
  border-radius: 12px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #000000;
  max-width: 300px;
  width: 100%;
  height: 64px;
  display: flex;

  > * {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
`;

function NinthVersion() {
  const { isOpen, openModal, closeModal } = useModalControls(false, {
    disableBodyScroll: true,
  });

  React.useLayoutEffect(() => {
    document.getElementById("root")?.setAttribute("style", "overflow:hidden");
  }, []);

  React.useEffect(() => {
    window.ym(93758124, "reachGoal", "show_v9");
  }, []);
  return (
    <main>
      {isOpen && (
        <VideoContainer>
          <VideoWrapper>
            <button onClick={closeModal} className="close_button">
              <img width={32} height={32} src={close} alt="" />
            </button>
            <iframe
              width={560}
              height={315}
              src="https://www.youtube.com/embed/SH-7FJPXLw4?autoplay=1"
              title="How to get $GRUM"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </VideoWrapper>
        </VideoContainer>
      )}
      <Header>
        <img height={250} src={grumpy} alt="" />
        <h1>
          Meet <span>$GRUM</span>
        </h1>
        <h5>
          The ultimate <span>Bitcoin</span> meme coin
        </h5>
      </Header>
      <BlockWrapper>
        <h2>
          Welcome to the <br />
          <span>$GRUM</span> Revolution!
        </h2>
        <p>
          Step into the future with Grumpy (<span>$GRUM</span>) - the ultimate
          Bitcoin memecoin. We're clawing our way to the top of the memecoin
          market, leaving the dogs and frogs in the dust.
        </p>
      </BlockWrapper>
      <BlockWrapper>
        <div className="cloud-left" />
        <h2>
          <span>$GRUM</span>: The Potential <br />
          100X Coin
        </h2>
        <p>
          With a rapidly expanding community and ambitious future plans,{" "}
          <span>$GRUM</span>
          is setting the stage to become the next sensation in the crypto world.
          We're building on the powerful <span>BRC20</span> protocol and getting
          ready for lift-off. Here's your chance to join before{" "}
          <span>$GRUM</span> makes its debut on major exchanges.
        </p>
      </BlockWrapper>
      <BlockWrapper>
        <h2>
          Get <span>$GRUM</span> Now Before <br />
          It's Too Late!
        </h2>
        <p>
          It's easy to join the Grumpy revolution! Just follow the steps in our{" "}
          <span>How to Buy $GRUM video</span> and become a part of the $GRUM
          movement today. Hurry, because once we're on the exchanges, prices are
          expected to skyrocket!
        </p>
        <HowToBuyGrum
          onClick={() => {
            openModal();
            window.ym(93758124, "reachGoal", "click_v9_howtobuy");
          }}
        >
          <img width={80} src={cat} alt="" />{" "}
          <div>
            <img src={play} alt="" />
            <div>
              How To Buy <span>$GRUM</span>
            </div>
          </div>
        </HowToBuyGrum>
      </BlockWrapper>
      <BlockWrapper>
        <h2>
          Our Partnership with <br />
          <img
            style={{ verticalAlign: "middle" }}
            width={22}
            height={22}
            src={logo}
            alt=""
          />{" "}
          Nicegram
        </h2>
        <p>
          We're excited to announce our partnership with Nicegram, providing 2.6
          million monthly users exclusive early access to <span>$GRUM</span>.
          Nicegram users, this is your opportunity to get in on the ground
          floor!
        </p>
        <p>
          We've joined forces with Nicegram, a platform with 2.6 million monthly
          active users, providing exclusive opportunities for early access to
          <span>$GRUM</span> and an exciting journey towards memecoin dominance.
        </p>
      </BlockWrapper>
      <BlockWrapper>
        <h2>
          The Future is Bright <br /> with <span>$GRUM</span>
        </h2>
        <p>
          We have exciting things coming down the pipeline: listings on top
          exchanges, a Grumpy-themed game, and more. But these future
          developments also mean potential price increases.
        </p>
        <Alert>
          Get your <span>$GRUM</span> today and lock in your position before
          lift-off!
        </Alert>
      </BlockWrapper>
      <BlockWrapper>
        <div className="cloud-right" />
        <h2>
          Last Chance: <span>Get $GRUM Now!</span>
        </h2>
        <p>
          This is your moment. When $GRUM hits the exchanges, we're expecting
          prices to climb. Join the revolution now or watch from the sidelines
          as others reach for the stars. The future is <span>$GRUM</span>.
          Secure your <span>$GRUM</span> today!
        </p>
      </BlockWrapper>
      <CtaButton
        onClick={() => window.ym(93758124, "reachGoal", "click_v9_main")}
      >
        <a href="ncg://special-offer-click?url=tg://resolve?domain=grumpynicegram">
          GET $GRUM ON SALE
        </a>
      </CtaButton>
    </main>
  );
}

export default NinthVersion;
