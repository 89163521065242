import styled from "styled-components";

export const VideoWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 80%;
  border-radius: 16px;
  background: #191919;
  overflow: hidden;
  z-index: 10;

  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
  }

  .close_button {
    cursor: pointer;
    z-index: 2;
    margin: 0;
    padding: 0;
    width: 32px;
    height: 32px;
    background: transparent;
    border: none;
    position: absolute;
    right: 6px;
    top: 6px;
  }
`;
export const Wrapper = styled.div`
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 10;
`;
