import styled from "styled-components";
import { BlockTitle, BlockWrapper, BlockText } from "../../shared/styled";

export const Header = styled(BlockWrapper)`
  text-align: center;
  position: relative;

  ${BlockTitle} {
    font-size: 56px;
    font-style: normal;
    font-weight: 700;
    line-height: 64px;
    margin-bottom: 0;
  }

  ${BlockText} {
    margin: 0;
  }
`;
