import React from "react";
import { Wrapper } from "./styled";

type CtaButtonProps = {
  href?: string;
  onClick?: () => void;
  children?: React.ReactNode;
};

const BASE_LINK =
  "ncg://special-offer-click?url=tg://resolve?domain=grumpynicegram";

function CtaButton({
  href = BASE_LINK,
  onClick = () => {},
  children,
}: CtaButtonProps) {
  return (
    <Wrapper onClick={onClick}>
      <a href={href}>{children}</a>
    </Wrapper>
  );
}

export { CtaButton, type CtaButtonProps };
