export const LANGUAGE_OPTIONS = [
  {
    img: "https://static.esimplus.net/storage/flags/gb.svg",
    label: "English",
    country: "United States",
    value: "en",
  },
  {
    img: "https://static.esimplus.net/storage/flags/ae.svg",
    label: "Arabic",
    country: "UAE",
    value: "ar",
  },
  {
    img: "https://static.esimplus.net/storage/flags/bn.svg",
    label: "Bengali",
    country: "Bangladesh",
    value: "bd",
  },
  {
    img: "https://static.esimplus.net/storage/flags/cn.svg",
    label: "Chinese",
    country: "China",
    value: "cn",
  },
  {
    img: "https://static.esimplus.net/storage/flags/de.svg",
    label: "Deutch",
    country: "Germany",
    value: "de",
  },
  {
    img: "https://static.esimplus.net/storage/flags/es.svg",
    label: "Spanish",
    country: "Spain",
    value: "es",
  },
  {
    img: "https://static.esimplus.net/storage/flags/ge.svg",
    label: "Georgian",
    country: "Georgia",
    value: "ka",
  },
  {
    img: "https://static.esimplus.net/storage/flags/ng.svg",
    label: "Hausa",
    country: "Nigeria",
    value: "ha",
  },
  {
    img: "https://static.esimplus.net/storage/flags/hk.svg",
    label: "Hong Kong",
    country: "Hong Kong",
    value: "hk",
  },
  {
    img: "https://static.esimplus.net/storage/flags/it.svg",
    label: "Italian",
    country: "Italy",
    value: "it",
  },
  {
    img: "https://static.esimplus.net/storage/flags/my.svg",
    label: "Malay",
    country: "Malaysia",
    value: "ms",
  },
  {
    img: "https://static.esimplus.net/storage/flags/ph.svg",
    label: "Filipino",
    country: "Philippines",
    value: "ph",
  },
  {
    img: "https://static.esimplus.net/storage/flags/pt.svg",
    label: "Portugal",
    country: "Portuguese",
    value: "pt",
  },
  {
    img: "https://static.esimplus.net/storage/flags/pk.svg",
    label: "Urdu",
    country: "Pakistan",
    value: "ur",
  },
  {
    img: "https://static.esimplus.net/storage/flags/vn.svg",
    label: "Vietnamese",
    country: "Viet Nam",
    value: "vi",
  },
];
