import React from "react";
import { VideoWrapper, VideoContainer, Wrapper } from "./styled";
import cat from "../../assets/cat.png";
import play from "../../assets/play.svg";
import { useModalControls } from "../../shared/hooks";
import close from "../../assets/close.svg";

function HowToBuyGrum({ onClick = () => {} }: { onClick?: () => void }) {
  const { isOpen, openModal, closeModal } = useModalControls(false, {
    disableBodyScroll: true,
  });
  return (
    <>
      {isOpen && (
        <VideoContainer>
          <VideoWrapper>
            <button onClick={closeModal} className="close_button">
              <img width={32} height={32} src={close} alt="" />
            </button>
            <iframe
              width={560}
              height={315}
              src="https://www.youtube.com/embed/SH-7FJPXLw4?autoplay=1"
              title="How to get $GRUM"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </VideoWrapper>
        </VideoContainer>
      )}
      <Wrapper
        onClick={() => {
          openModal();
          onClick();
        }}
      >
        <img width={80} height={80} src={cat} alt="" />
        How To Buy $GRUM
        <img width={24} height={24} src={play} alt="" />
      </Wrapper>
    </>
  );
}

export { HowToBuyGrum };
